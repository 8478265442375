.App {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f5f5;
  min-height: 100vh;
  /*  background: #fff; */
}

.ContentContainer {
  padding-top: 2em;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  width: 100%;
  min-height: 600px;
}
