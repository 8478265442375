.PaperContainer {
  border-radius: 30px 30px 30px 30px !important;
  width: 35%;
  min-height: 700px;
  padding: 3.5em;
  display: flex;
  justify-content: center;
  text-align: justify;
  margin-bottom: 3em;
}

.Container {
  width: 100%;
}

.GeneralText {
  font-size: 1.1em;
}

.StartScreenP {
  font-size: 16px;
  font-weight: 500;
}

.HeaderText {
  font-size: 1.3em;
  margin: 16px 8px;
  background-color: #0097a7;
  color: white;
  padding: 8px;
}

.LabelText {
  border: 1px solid #0097a7;
  padding: 8px;
}

.FlexCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FlexStart {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.FlexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.ButtonStyle {
  background-color: #cc4125 !important;
  width: 14em;
  height: 3.3em;
  border-radius: 0 !important;
  span {
    text-transform: none;
    color: white;
    font-weight: 600;
  }
}

.SelectButton {
  background-color: white !important;
  border: 1px solid #0097a7 !important;
  border-radius: 0 !important;
  width: 100%;
  height: 3em;
  span {
    text-transform: none !important;
    font-size: 0.9em;
    line-height: 1.3;
    white-space: pre;
  }
}
.SelectButtonNoWS {
  background-color: white !important;
  border: 1px solid #0097a7 !important;
  border-radius: 0 !important;
  width: 100%;
  height: 3em;
  span {
    text-transform: none !important;
    font-size: 0.9em;
    line-height: 1.3;
    text-align: left;
  }
}

.Selected {
  background-color: #0097a7 !important;
  span {
    color: white;
  }
}

.MuiMobileStepper-progress {
  width: 100% !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #0097a7 !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: rgba(0, 151, 167, 0.1) !important;
}

.StepperPercent {
  width: 100%;
  text-align: right;
  float: right;
  padding: 0px 8px;
  color: #0097a7;
}

.StepperBack {
  margin: 0.5em !important;
  background-color: white !important;
  width: 9em;
  height: 2.7em;
  border-radius: 0 !important;
  border: 1px solid #0097a7 !important;
  box-shadow: 2px 2px 1px rgba(50, 50, 50, 0.4);
  span {
    text-transform: none;
    color: #0097a7;
    font-weight: 600;
  }
}

.StepperNext {
  margin: 0.5em !important;
  background-color: #0097a7 !important;
  width: 9em;
  height: 2.7em;
  border-radius: 0 !important;
  border: 1px solid #0097a7 !important;
  box-shadow: 2px 2px 1px rgba(50, 50, 50, 0.4);
  span {
    text-transform: none;
    color: white;
    font-weight: 600;
  }
}

.TextInput {
  width: 99.5%;
  padding: 12px 0px 10px 0px;
  margin: 16px 0px 16px 0px;
}

.NumberInput {
  width: 30%;
  padding: 12px 0px 10px 0px;
  margin: 16px 0px 16px 0px;
}

.RadioLabel {
  margin: 0;
  margin-bottom: 0.5em;
  padding: 4px;
  font-size: 0.7em;
  font-weight: 500;
  border: 1px solid #0097a7;
  line-height: 1.2;
}
.RadioValues {
  margin: 0;
  margin-bottom: 0.5em;
  padding: 4px;
  font-size: 0.7em;
  font-weight: 500;
  text-align: center;
  white-space: pre;
  line-height: 1.2;
}

.Border {
  border: 1px solid #0097a7;
}

//RADIO BUTTON
input[type="radio" i] {
  cursor: pointer;
}

.RadioContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.RadioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.RadioCheckmark {
  position: absolute;
  top: 10%;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: transparent;
  border: 1px solid #0097a7;
  border-radius: 50%;
  margin: -9px 0px 16px 0px;
}

/* On mouse-over, add a grey background color */
.RadioContainer:hover input ~ .RadioCheckmark {
  background-color: rgba(243, 243, 243, 0.3);
}

/* When the radio button is checked, add a blue background */
.RadioContainer input:checked ~ .RadioCheckmark {
  background-color: white;
}
/* Show the indicator (dot/circle) when checked */
.RadioContainer input:checked ~ .RadioCheckmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.RadioCheckmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 6px;
  left: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0097a7;
  /* background: white; */
}
.NoPadding {
  padding: 0 !important;
}

.BadgeStyle {
  /*   width: 2em;
  height: 2.5em; */
}

.MuiIconButton-colorSecondary {
  color: #0097a7 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: rgba(26, 177, 223, 0.04) !important;
}

.MuiIconButton-root:hover {
  background-color: rgba(26, 177, 223, 0.04) !important;
}
.MV {
  max-width: 11.333333% !important;
  flex-basis: 11.333333% !important;
}
@media screen and (max-width: 1400px) {
  .PaperContainer {
    width: 50%;
  }
}
@media screen and (max-width: 900px) {
  .PaperContainer {
    width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .PaperContainer {
    width: 100%;
    max-height: none;
    padding: 2em;
    border-radius: 0 !important;
    margin: 0;
  }
  .ContentContainer {
    padding: 0 !important;
  }
  .MV {
    max-width: 17% !important;
  }
}

@media screen and (max-width: 500px) {
  .PaperContainer {
    width: 100%;
    max-height: none;
    padding: 1em;
    border-radius: 0 !important;
    margin: 0;
  }
  .ContentContainer {
    padding: 0 !important;
  }
  .MV {
    max-width: 11% !important;
  }
  .RadioValues {
    font-size: 0.5em !important;
  }
  .SelectButton {
    height: fit-content;
  }
  .SelectButtonNoWS {
    height: fit-content;
  }
}
